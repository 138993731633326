import React, { useState, useEffect } from "react";
import { Container, Navbar } from "react-bootstrap";
import { Button} from 'react-bootstrap';
import { Modal} from 'react-bootstrap';
import "./spf.scss";
import store from "store";
export default function SPF() {
  //spf syntax symbols predifined to be used
  const spf_version = "v=spf1";
  //states to alter actions
  const [domain, setDomain] = useState("your domain");
  const [mx, setmx] = useState("");
  const [ips, setIps] = useState("");
  const [hosts, setHosts] = useState("");
  const [domains, setDomains] = useState("");
  const [ipallowed, setIpallowed] = useState("");
  const [mailHandling, setMailhandling] = useState("");
  //State for checbox selected / Not selected
  const [selection2, setSelection2] = useState("");
  const [selection3, setSelection3] = useState("");
  const [selection4, setSelection4] = useState("");
  //original spf string
  const [spf, setSpf] = useState("");

  //option number two function
  const optn2 = (e) => {
    setSelection2(e.target.value);
    e.target.value === "mx" ? setmx(e.target.value) : setmx("");
  };

  //option number three function
  const optn3 = (e) => {
    setSelection3(e.target.value);
    e.target.value === "a" ? setIpallowed(e.target.value) : setIpallowed("");
  };

  //option number four function
  const optn4 = (e) => {
    setSelection4(e.target.value);
    setMailhandling(e.target.value);
  };
  const listIP = () => {
    let str = ips;
    let new_arr = str.split("  ");
    setIps(new_arr);
  };
  const listHosts = () => {
    let str = hosts;
    let new_arr = str.split("  ");
    setHosts(new_arr);
  };
  const listDomains = () => {
    let str = domains;
    let new_arr = str.split("  ");
    setDomains(new_arr);
  };
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  //update string
  useEffect(() => {
    //update string
    setSpf(
      `${spf_version} ${ips} ${hosts} ${domains} ${mx} ${ipallowed} ${mailHandling}`
    );
  }, [mailHandling, mx, ipallowed, listIP, listDomains, listHosts]);

  return (        
    <div>     

        <div className="flexwrapper" id ="navbar">
          <div className="flex">
            <div className="txt">
            <h3>
              <b>SPF RECORD</b>:{" "}
            </h3>
          </div>
          <div className="input">
            <input
              type="text"
              name="spfrecord"
              style={{
                color: "white",
                fontSize: "large",
                border: "none",
                background: "#72D8D3",
              }}
              value={spf.replace(/\s{2,}/g,' ')}
              readOnly
              className="form-control"
            />
          </div>
          <div className="txt">            
            <Button variant="info" onClick={handleShow}>Help !</Button>                  
            <Button variant="info" onClick={() => {navigator.clipboard.writeText(this.spfrecord.value)}}>Copy</Button>
            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>What are SPF Records?</Modal.Title>
              </Modal.Header>
              <Modal.Body>SPF records will help to define which hosts are allowed to send mails for your domain. This can help preventing spam.</Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>                
              </Modal.Footer>
            </Modal> 
          </div>
        </div>
      </div>
        <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      </div>
      <Container>
      <div className="zero">                  
          <h1>SPF Record Builder - spfbuilder.com</h1>
        </div>
        <div className="one">                  
          <div className="number">
            <h3 className="mr-2 rotated">#1</h3>
          </div>
          <div className="input">
            <input
              onChange={(e) => setDomain(e.target.value)}
              type="text"
              placeholder="Your Domain Name"
              className="form-control"
            />
          </div>
        </div>
        <div className="two">
          <div className="number">
            <h3 className="mr-3  rotated">#2</h3>
          </div>
          <div className="heading">
            <h3>
              Should any servers listed as incoming MX servers for {domain} be
              able to send email for this domain?
              <div className="inputs">
              <label>
                  <input
                    onChange={optn2}
                    checked={selection2 === "mx"}
                    value="mx"
                    type="radio"
                    className="mt-3"
                    name="mx"
                  />
                  <small className="mr-3 ml-2">Yes (recommended)</small>                
                </label>
                <label>
                  <input
                    name="2"
                    value="2"
                    onChange={optn2}
                    checked={selection2 === "2"}
                    type="radio"
                  />
                  <small className="ml-2">No</small>
                </label>
              </div>
            </h3>
          </div>
        </div>
        {/* This is step number 3  */}
        <div className="three">
          <div className="number">
            <h3 className="mr-3  rotated">#3</h3>
          </div>
          <div className="heading">
            <h3>
              Should the current IP address of the host, be allowed to send
              email for {domain}?
              <div className="inputs">
                <label>
                  <input
                    onChange={optn3}
                    value="a"
                    type="radio"
                    checked={selection3 === "a"}
                    className="mt-3"
                    name="a"
                  />
                  <small className="mr-3 ml-2">Yes (recommended)</small>
                </label>
                <label>
                  <input
                    value="3"
                    name="3"
                    onChange={optn3}
                    checked={selection3 === "3"}
                    type="radio"
                  />
                  <small className="ml-2">No</small>
                </label>
              </div>
            </h3>
          </div>
        </div>
        {/* This is step number 4  */}
        <div className="four">
          <div className="number">
            <h3 className="mr-3  rotated">#4</h3>
          </div>
          <div className="heading">
            <h3>
              How strict should the servers be that handle the emails?
              <div className="inputs">
                <label>
                  <input
                    checked={selection4 === "-all"}
                    onChange={optn4}
                    value="-all"
                    type="radio"
                    className="mt-3"
                    name="-all"
                  />
                  <small className="mr-3 ml-2">
                    Fail (Reject non-compliant requests)
                  </small>
                </label>
                <br />
                <label>
                  <input
                    checked={selection4 === "~all"}
                    onChange={optn4}
                    name="~all"
                    value="~all"
                    type="radio"
                  />
                  <small className="ml-2">
                    Soft Fail (Accept, but not mark non-compliant requests)
                  </small>
                </label>
                <br />
                <label>
                  <input
                    checked={selection4 === "?all"}
                    onChange={optn4}
                    name="?all"
                    value="?all"
                    type="radio"
                  />
                  <small className="ml-2">
                    Neutral (Mail will be probably accepted)
                  </small>
                </label>
              </div>
            </h3>
          </div>
        </div>
        {/* Advanced options */}
        <div className="text-left mt-3">
          <h1 className="mt-3 mb-3" style={{ color: "white" }}>
            Advanced Options:
          </h1>
          <div
            className="jumbotron"
            style={{ background: "rgba(204, 204, 204, 0.5)" }}
          >
            <h3 className="text-left mt-3">
              List any IP address(es) or subnet(s) authorized to deliver or
              relay mail for {domain}:
            </h3>
            <input
              onChange={(e) => setIps(e.target.value == '' ? '' : 'ip4:' + e.target.value)}
              type="text"
              placeholder="IP Address(es) in CIDR format. Seprated by space written as i.e 192.168.10.1."
              className="mt-2 form-control"
            />

            <h3 className="text-left mt-5">
              List any hostname(s) or server(s) authorized to deliver or relay
              mail for {domain}:
            </h3>
            <input
              onChange={(e) => setHosts(e.target.value == '' ? '' : 'a:' + e.target.value)}
              type="text"
              placeholder="Host name(es) seperated by a space. i.e www.example.com"
              className="form-control mt-2"
            />            
            <h3 class Name="text-left mt-5">
              List any domain(s) or service(s) authorized to deliver or relay
              mail for {domain}:
            </h3>
            <input
              onChange={(e) => setDomains(e.target.value == '' ? '' : '#include:' + e.target.value)}
              type="text"
              placeholder="Domain name(es) seprated by a space. i.e www.example.com"
              className="form-control mt-2"
            />
          </div>
          <hr></hr>
          <p>
				  <a href="https://www.huestel-gmbh.de/impressum/">Imprint</a><br/><br/><a href="https://www.spambarrier.de">Cloud AntiSpam</a> - <a href="https://www.mxwatchdog.com">Mail Flow Round-Trip Monitoring</a> - <a href="https://www.axigen.eu">Windows / Linux Mailserver AXIGEN</a>								
			  </p>
        </div>
      </Container>
    </div>
  );
}
